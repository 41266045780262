import React from 'react'
import tw, { styled } from 'twin.macro'
import { Link } from 'gatsby'
import ReadMoreButton from '../ReadMoreButton'

export default function Quote(props) {
  const { quote, linkTo, width, readMoreHide } = props.content

  const StyledLink = styled(Link)`
    & {
      ${tw`font-bold text-navy`}
    }
    &:before {
      ${tw`font-bold text-white bg-navy inline-block rounded-full text-center text-2xl leading-none mr-3 mt-4`}
      content:'›';
      height: 30px;
      width: 30px;
    }
  `

  return (
    <section
      css={[tw`relative`, width === 'contained' && tw`container md:px-10 px-6`]}
      className={`qoute mm-py-dynamic ${width === 'space' && 'mm-mx-dynamic'} ${
        readMoreHide && 'readMoreHide'
      }`}
    >
      <div css={tw`md:flex justify-between`}>
        <div css={tw`max-w-md`}>
          <div
            css={tw`text-2xl md:pb-10`}
            dangerouslySetInnerHTML={{ __html: quote }}
          ></div>
          <StyledLink to={`/` + linkTo[0]?.uri}>{linkTo[0]?.title}</StyledLink>
        </div>
        <div css={tw`hidden lg:block`}>
          <svg alt="" width="105" height="92">
            <path
              id="Path_2549"
              data-name="qoute"
              d="M169.711,299.421v-39.96a84.971,84.971,0,0,1,2.34-20.34,51.639,51.639,0,0,1,7.2-16.74,36.86,36.86,0,0,1,12.42-11.52,34.934,34.934,0,0,1,17.64-4.32v18a16.018,16.018,0,0,0-10.08,3.06,23,23,0,0,0-6.48,8.1,36.741,36.741,0,0,0-3.42,11.34,87.5,87.5,0,0,0-.9,12.42h21.24v39.96Zm65.88,0v-39.96a84.971,84.971,0,0,1,2.34-20.34,51.639,51.639,0,0,1,7.2-16.74,36.86,36.86,0,0,1,12.42-11.52,34.934,34.934,0,0,1,17.64-4.32v18a16.018,16.018,0,0,0-10.08,3.06,23.016,23.016,0,0,0-6.48,8.1,36.768,36.768,0,0,0-3.42,11.34,87.493,87.493,0,0,0-.9,12.42h21.24v39.96Z"
              transform="translate(-169.711 -206.541)"
              fill="#1d282a"
            />
          </svg>
        </div>
      </div>
      <ReadMoreButton text="Læs mere" />
    </section>
  )
}
