import React from 'react'
import tw from 'twin.macro'

const buttonClick = (e) => {
  e.target.parentElement.classList.remove('readMoreHide')
}

const StyledButton = tw.button`
  hidden
`
export default function ReadMoreButton({ text }) {
  return (
    <StyledButton onClick={buttonClick} className="readMore">
      {text}
    </StyledButton>
  )
}
